import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

export class UserDetails extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "id",
                TrOnlyClassName: 'tsc',
                text: "id",
                className: "tsc",
                align: "left"
            },
            {
                key: "phone",
                TrOnlyClassName: 'tsc',
                text: "msisdn",
                className: "tsc",
                align: "left"
            },
            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },
            {
                key: "maccount",
                TrOnlyClassName: 'tsc',
                text: "Account",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "Balance",
                className: "tsc",
                align: "left"
            },
            {
                key: "mjoiningKeyword",
                TrOnlyClassName: 'tsc',
                text: "Join KeyWord",
                className: "tsc",
                align: "left"
            },
            {
                key: "JackpotWinners",
                TrOnlyClassName: 'tsc',
                text: "Jackpot Winners",
                className: "tsc",
                align: "left"
            },
            {
                key: "registrationChannel",
                TrOnlyClassName: 'tsc',
                text: "Registration Channel",
                className: "tsc",
                align: "left"
            },

        ];

        this.columns2 = [
            {
                key: "mticketRate",
                TrOnlyClassName: 'tsc',
                text: "Ticket Rate",
                className: "tsc",
                align: "left"
            },

            {
                key: "mnetCashValue",
                TrOnlyClassName: 'tsc',
                text: "Net Cash Value",
                className: "tsc",
                align: "left"
            },

            {
                key: "mjackpotAmount",
                TrOnlyClassName: 'tsc',
                text: "JackpotAmount",
                className: "tsc",
                align: "left"
            },
            {
                key: "mminiJackpotWinner",
                TrOnlyClassName: 'tsc',
                text: "MiniJackPot",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbonus",
                TrOnlyClassName: 'tsc',
                text: "Bonus",
                className: "tsc",
                align: "left"
            },
            {
                key: "bonus_dates",
                TrOnlyClassName: 'tsc',
                text: "Bonus Date",
                className: "tsc",
                align: "left"
            },

            {
                key: "mbonusAllowed",
                TrOnlyClassName: 'tsc',
                text: "Bnus Allowed",
                className: "tsc",
                align: "left"
            },


        ]

        this.columns3 = [
            {
                key: "mfreeEntries",
                TrOnlyClassName: 'tsc',
                text: "Free Entiries",
                className: "tsc",
                align: "left"
            },
            {
                key: "msupaFreeEntries",
                TrOnlyClassName: 'tsc',
                text: "Super Free Entiries",
                className: "tsc",
                align: "left"
            },

            {
                key: "mtimeCreated",
                TrOnlyClassName: 'tsc',
                text: "TIme Active",
                className: "tsc",
                align: "left"
            },

            {
                key: "mactive",
                TrOnlyClassName: 'tsc',
                text: "Active",
                className: "tsc",
                align: "left"
            },
            {
                key: "dates2",
                TrOnlyClassName: 'tsc',
                text: "Last Date Active",
                className: "tsc",
                align: "left"
            },
            {
                key: "mpromoCode",
                TrOnlyClassName: 'tsc',
                text: "Promotion Code",
                className: "tsc",
                align: "left"
            },

            {
                key: "mticketBundle",
                TrOnlyClassName: 'tsc',
                text: "Ticket Bundled",
                className: "tsc",
                align: "left"
            },
        ]
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: false,
            pagination: 'basic',
            page_size: 10,
            show_info: false,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads..."
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {


        this.setState({ isLoading: true });
        axios.get(baseURL + 'customerOneDetails/' + localStorage.getItem("phone"), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((response) => {
                console.log("bayo", response.data)
                this.setState({
                    admins: response.data,
                    isLoading: false
                },
                    function () {

                        axios.get(baseURL + 'getCountOutMsgs/' + localStorage.getItem("phone"), {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                        }).then((response) => {
                            localStorage.setItem('customerMsgOut', response.data.total_page)

                        })

                        axios.get(baseURL + 'countbound/' + localStorage.getItem("phone"), {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                        }).then((response) => {
                            localStorage.setItem('counts', response.data.total_page)

                        })
                        var data = [];
                        for (let i = 0; i < this.state.admins.length; i++) {
                            let mbalance;
                            let xactive;


                            let mail;
                            let maccount;
                            let phone;
                            let mjoiningKeyword;
                            mail = { JackpotWinners: this.state.admins[i].JackpotWinner.toString() }

                            if (this.state.admins[i].account === null) {
                                maccount = { maccount: "____" };
                            } else {
                                maccount = { maccount: this.state.admins[i].account };
                            }
                            mbalance = { mbalance: this.state.admins[i].balance.toString() }
                            let mticketRate = { mticketRate: this.state.admins[i].ticketRate.toString() }
                            let mnetCashValue = { mnetCashValue: this.state.admins[i].netCashValue.toString() }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            if (this.state.admins[i].joiningKeyword === null) {
                                mjoiningKeyword = { mjoiningKeyword: "_" };
                            } else {
                                mjoiningKeyword = { mjoiningKeyword: this.state.admins[i].joiningKeyword };
                            }

                            localStorage.setItem('customerID', this.state.admins[i].id)


                            let mjackpotAmount = { mjackpotAmount: this.state.admins[i].jackpotAmount.toString() }
                            let mfreeEntries = { mfreeEntries: this.state.admins[i].freeEntries.toString() }
                            let mbonusAllowed = { mbonusAllowed: this.state.admins[i].bonusAllowed.toString() }
                            let mticketBundle = { mticketBundle: this.state.admins[i].ticketBundle.toString() }
                            let msupaFreeEntries = { msupaFreeEntries: this.state.admins[i].supaFreeEntries.toString() }
                            let mbonus = { mbonus: this.state.admins[i].bonus.toString() }
                            let mminiJackpotWinner = { mminiJackpotWinner: this.state.admins[i].miniJackpotWinner.toString() }

                            let mpromoCode = { mpromoCode: this.state.admins[i].promoCode.toString() }

                            let dates = { dates2: moment(this.state.admins[i].date_last_active).format('DD MMM, YYYY HH:MM') };

                            let bonus_dates = { bonus_dates: moment(this.state.admins[i].bonusExpiryDate).format('DD MMM, YYYY HH:MM') };


                            let mtimeCreated = { mtimeCreated: moment(this.state.admins[i].timeCreated).format('DD MMM, YYYY HH:MM') };

                            xactive = { mactive: this.state.admins[i].active.toString() };


                            data.push(Object.assign(xactive, mail, mjoiningKeyword, maccount, phone, mpromoCode, mminiJackpotWinner, mnetCashValue, mjackpotAmount, bonus_dates, mfreeEntries,
                                mbonusAllowed, mticketBundle, msupaFreeEntries, mbonus, bonus_dates, mtimeCreated, mticketRate, mbalance, dates, this.state.admins[i]));
                            this.setState({
                                data: data,
                                number: this.state.admins[i].msisdn
                            })
                        }

                    }
                );
            })
    }

    render() {
        // console.log("Load", isLoading);
        return (
            <div>
                <>
                    <Col md={12}>
                        <h3>User Details</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <Link to="/user">
                                        <Button className="pull-right"
                                            color="primary" outline>Go Back</Button><br /> <br /><br />
                                    </Link>
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="col-md-8 offset-md-2">

                                                <Link to="/game">
                                                    <Button className=""
                                                        color="primary" outline>Bets: Games </Button><br /> <br /><br />
                                                </Link>
                                                <Link to="/statement">
                                                    <Button className=""
                                                        color="primary" outline>Finance: Statements</Button><br /> <br /><br />
                                                </Link>

                                                <h3 className=""
                                                    color="primary" >Messages</h3><br /> <br />
                                                <Link to="/incoming">
                                                    <Button className=""
                                                        color="primary" outline> Incoming Messages </Button><br /> <br /><br />
                                                </Link>
                                                <Link to="/outgoing">
                                                    <Button className=""
                                                        color="primary" outline> Outgoing Messages </Button><br /> <br /><br />
                                                </Link>
                                                <Link to="/allmessages">
                                                    <Button className=""
                                                        color="primary" outline> All Messages </Button><br /> <br /><br />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <h3><b>More about {localStorage.getItem("phone")}</b></h3><br /><br />
                                            <h5><b>First Row</b></h5>
                                            < ReactDatatable config={this.config}
                                                records={this.state.data}
                                                id="tsc"

                                                columns={this.columns}
                                                loading={this.state.isLoading}
                                                extraButtons={this.extraButtons}
                                            />

                                            <br></br>
                                            <br></br>

                                            <h5><b>Second Row</b></h5>
                                            < ReactDatatable config={this.config}
                                                records={this.state.data}
                                                id="tsc"
                                                columns={this.columns2}
                                                loading={this.state.isLoading}
                                                extraButtons={this.extraButtons}
                                            />
                                            <br></br>
                                            <br></br>
                                            <h5><b>Third Row</b></h5>
                                            < ReactDatatable config={this.config}
                                                records={this.state.data}
                                                id="tsc"
                                                columns={this.columns3}
                                                loading={this.state.isLoading}
                                                extraButtons={this.extraButtons}
                                            />

                                        </div></div>
                                </div>

                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
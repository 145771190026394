import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';

import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';

export class UserSearch extends React.Component {

    constructor(props) {
        super(props)


        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            showError: false,
            isShowError: false,
        }
    }




    onSubmit(e) {
        e.preventDefault();


            let formData = {
                "msisdn": this.state.phone_number
            }
            console.log("DATA", JSON.stringify(formData))
            this.setState({ isLoading: true });

            axios.get(baseURL + 'customersone/' + this.state.phone_number, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            }).then((response) => { 
                    console.log("bayo", response.data)
                    if (response.data.status) {
                      
                        this.setState({
                            statusMessage: "Success! Redirecting....",
                            isShowError: true, isLoading: false, showError: false,

                        });
                        // alert(response.data.msisdn)
                        localStorage.setItem('phone', this.state.phone_number)
                        //   localStorage.setItem('balance', response.data.balance)
                        //   localStorage.setItem('balance', response.data.netCashValue)
                        //   localStorage.setItem('mtime', response.data.time)

                        window.setTimeout(function () {
                            window.location.href = "/userdetail";
                            this.setState({
                                statusMessage: response.data.message,
                                isShowError: true, isLoading: false, showError: false,
                            });
                        }, 1000);

                    } else {

                        this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                    }
                }).catch(error => {
                    console.log('bayoo', error.response)
                    this.setState({
                        showError: true,
                        isShowError: false,
                        statusMessage: error.response.data.message,
                        submitted: true,
                        isLoading: false
                    });
                });
       

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div className="pcoded-main-container">

                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <peopleView /> : null
                        }

                    </div>
                )}

                {!hideComponent && (
                    <>

                        <Col md={12}>
                            <h3>User Search</h3><br />

                        </Col>
                        <Col md={12} lg={12}>
                            <Card>
                                <CardBody><br />

                                    <div className="card__title">
                                        <h5 className="bold-text">Fill the Below Field to search </h5>
                                    </div>

                                    {this.state.showError ? <div style={{ color: 'red' }}>
                                        {this.state.statusMessage}

                                    </div> : null}<br></br>

                                    {this.state.isShowError ? (
                                        <div
                                            color="success"
                                            style={{ fontSize: "13px", color: "green" }}>
                                            {this.state.statusMessage}
                                        </div>

                                    ) : null}<br></br>
                                    <form className="col-md-8 offset-md-2" onSubmit={this.onSubmit}>


                                        <div className="form-group">
                                            <label className="form-label">Customer Phone</label>
                                            <input id="input" type="number" className="form-control input-md"
                                                name="phone_number" required placeholder="Enter Phone Number"
                                                value={this.state.phone_number} onChange={this.handleChange} />
                                        </div>
                                        <br />
                                        <div className="col-md-12">
                                            <Button type="submit" outline color="primary" className="btn-paypal btn-lg pull-right" >
                                                {this.state.isLoading ? "Please Wait..." : "Search"}  <i className="fa fa-search"></i></Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </>

                )
                }

            </div>
        )

    }

}

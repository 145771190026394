import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Faq from "react-faq-component";

export class FAQs extends React.Component {
    constructor(props) {
        super(props);

        this.data = {
            title: "TERMS AND CONDITIONS",
            rows: [
                {
                    title: "1 GENERAL",
                    content: `1.1.	LUCKY NUMBER is licensed and regulated in Kenya by BCLB, 
                    license number 1149 Trading as ‘LUCKY BOX’. <br/>
                    1.2The platform and the games are offered by MOBIPESA LIMITED. (Hereinafter referred to as ‘LUCKY BOX’).<br/>
                    1.3.	The following terms & conditions apply to the “LUCKY BOX” promotion. By participating in this promotion,
                     you will be deemed to have read, understood and accepted the terms and conditions.<br/>
                     1.4.	These T&C constitute a binding agreement between you and LUCKY BOX.  <br/>
                     1.5.	MOBIPESA LIMITED is a limited liability company incorporated in Nairobi Kenya, Company Registration number 
                     CPR/2014/137049 and subject to Kenyan law, having its registered address at RED COMMERCIAL COMPLEX 2ND FLOOR MOMBASA ROAD.

                     1.6.	LUCKY BOX is licensed and regulated in Kenya by BCLB, license number 1149. <br/>
                     1.7.	LUCKY BOX reserves the right to amend these T&C. It is your sole responsibility to review this agreement and amendments each time you play, 
                     together with the specific rules for each game You choose to participate in, in order to remain updated with all amendments.<br/>
                     1.8.	You fully understand and agree to be bound by the terms and conditions contained herein and as may be amended by us from time to time.
                     <br/>
                     1.9.	Rules and explanations of the rules of each game provided separately on the platform
                      are incorporated into these T&C by reference.`,
                },
                {
                    title: "2.0	ELIGIBILITY",
                    content: `2.1 The promotion is open to persons who are 18 years of age and above.<br/>
                    2.2	The promotion is open to all subscribers of Safaricom pre-pay and post-pay mobile tariffs`,
                },
                {
                    title: "3.0	PROMOTION DURATION",
                    content: `3.1	The promotion will run for 90 days.`,
                },
                {
                    title: "4.0	PARTICIPATION",
                    content: `4.1 This competition is through Short Message Service (SMS) short code, Mobile App and USSD. <br/>
                    4.2	Participants shall register to participate in the prize competition by sending an SMS to the short code “20549” with the word PLAY. <br/>
                    4.3	Participants can send the word "STOP" to opt out of the competition.<br/>
                    4.4	For Lucky Box, the Participant will select a box from 6 box selection by sending the word LB#StakeAmount to 20549 and then send their box option for instant wins.<br/>
                    4.5	For Lucky Player, the Participant will send the word LP#StakeAmount to receive the list of six lucky players. They will respond with their number selection to 20549.<br/>
                    4.6	Minimum stake is Ksh 20 and Maximum stake is Ksh 1000. <br/>
                    4.7	For Jackpot, the participants will send the word JP#LuckyBoxNumber to 20549 each Jackpot entry is Ksh 50. A Participant will get a maximum of 4 entries daily for the jackpot.<br/>
                    4.8	This is a Premium Rate Service and SMS sent to the short code “20549” will be charged at Ksh 2. The responses back to the Participant will not be charged.<br/>
                    4.9	The more one plays, the more chances to win. There is no restriction to the number of times that you can participate.<br/>
                    4.10	Participants are allowed to have only one member account.`,
                },
                {
                    title: "5.0	PRIZES",
                    content: `5.1	The prizes are won instantly whenever a participant plays. The prize won will be sent via a confirmation text from 20549.<br/>
                    5.2	For Grand Jackpot, the winning prize will be advertised as it runs. The grand draw will include all entries.`,
                },
                {
                    title: "6.0	DEPOSITS TO MEMBER ACCOUNT",
                    content: `6.1	The minimum deposit amount is Ksh 10. The maximum is Ksh 20000.<br/>
                    6.2	All deposits shall be done via M-Pesa Paybill 658979, Account LUCKYBOX. <br/>
                    6.3	Participants trying to stake with insufficient balance in the account will be notified to top up before proceeding to play.<br/>
                    6.4	You will not receive any interest of any kind on funds deposited or held in your account, including any prize winnings.<br/>
                    6.5	Carrier charges apply.<br/>
                    6.6	If LUCKY BOX mistakenly credits Your Member Account with winnings that do not belong to you, whether due to a technical or human error or otherwise, the amount will remain property of LUCKY BOX and the amount will be transferred from your Member Account.<br/>
                    6.7	If prior to LUCKY BOX becoming aware of the error, you withdraw funds that do not belong to you, without prejudice to other remedies and actions that may be available at law, the mistakenly paid amount will constitute a debt owed by you to LUCKY BOX.<br/>
                    6.8	In the event of an incorrect crediting, you are obliged to notify LUCKY BOX immediately by phone.`,
                },
                {
                    title: "7.0	WITHDRAWAL FROM MEMBER ACCOUNT",
                    content: `7.1	A member can withdraw from their wallet via SMS, Web or App. To withdraw via SMS send Withdraw#Amount to 20549. On web or APP go to Accounts, then withdraw and enter the amount you wish to withdraw.<br/>
                    7.2	Carrier charges apply.`,
                },
                {
                    title: "8.0	CLOSING OF ACCOUNT",
                    content: `8.1	You may close your account at any time and LUCKY BOX will return to you 
                    any and all funds from Your Member Account subject to the deduction of relevant withdrawal charges<br/>
                    8.2	The method of repayment will be at our absolute discretion.<br/>
                    8.3	LUCKY BOX reserves the right to close Your Member Account and to refund to you the "Account balance", subject to the deduction of relevant withdrawal
                     charges, at LUCKY BOX’s absolute discretion and without any obligation to state a reason or give prior notice.`,
                },
                {
                    title: "9.0	RESPONSIBLE GAMIN",
                    content: `9.1	 You may at your discretion choose to exclude yourself from playing any
                     games on our PLATFORM. This right of self-exclusion may be for a definite or indefinite period of time.`,
                },
            ],
        };
        this.styles = {
            // bgColor: 'white',
            titleTextColor: "#1c0c12",
            rowTitleColor: "#1c0c12",
            // rowContentColor: 'grey',
            // arrowColor: "red",
        };
        this.config = {
            animate: true,
            arrowIcon: "V",
        };
    }




    render() {
        return (
            < >
                < Col md={12} lg={12} >
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div>
                                </div>
                                <Faq data={this.data} config={this.config}  styles={this.styles} />
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </>
        );
    }
}
import React from 'react';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

import getTooltipStyles from '../../../../shared/helpers';



const NO1 = localStorage.getItem('entity');
const NO7 = localStorage.getItem('branch');
const NO2 = localStorage.getItem('staffs');
const NO3 = localStorage.getItem('students');
const NO4 = localStorage.getItem('parents');
const NO5 = localStorage.getItem('nfc-tags');
const NO6 = localStorage.getItem('devices');


const data = [
  { name: "Entities", a: NO1 },
  { name: 'Branches', a: NO7 },
  { name: 'Staffs', a: NO2 },
  { name: 'Parents', a: NO4 },
  { name: 'Students', a: NO3 },
  { name: 'NFC-Tags', a: NO5 },
  { name: 'NFC-Devices', a: NO6 }];

const ABTestingAnalytics = ({ t, dir, themeName }) => (
  <Panel md={12} lg={12} xl={12} title={t('translation.data')}>
    <div dir="ltr">
      <ResponsiveContainer height={250} className="dashboard__area">
        <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
          <XAxis dataKey="name" tickLine={false} reversed={dir === 'rtl'} />
          <YAxis tickLine={false} orientation={dir === 'rtl' ? 'right' : 'left'} />
          <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
          <Legend />
          <CartesianGrid />
          <Area name="Data" type="monotone" dataKey="a" fill="#4ce1b6" stroke="#4ce1b6" fillOpacity={0.2} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  </Panel>
);
ABTestingAnalytics.propTypes = {
  t: PropTypes.func.isRequired,
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(withTranslation('common')(ABTestingAnalytics));

import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { TOKEN } from '../../_helpers/token';
import FileUploadDefault from '../CSV/components/FileUploadDefault';
import { Contacts } from './contacts';

export class CreateContacts extends React.Component {

    constructor(props) {
        super(props)


        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            showError: false
        }
    }




    onSubmit(e) {
        e.preventDefault();

        let formData = {
            "msisdn": this.state.msisdn
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });

        axios.post(baseURL + 'contacts', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": "Bearer " + TOKEN
            },
        })
            .then((response) => {
                console.log("bayo", response)
                // eslint-disable-next-line
                if (response.data.status == true) {
                    console.log("records", formData)
                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });

                    setTimeout(() => this.setState({ statusMessage: response.data.status_message, msisdn: "", isShowError: false, isLoading: false }), 2000);

                } else {
                    console.log("records", formData)
                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                    setTimeout(() => this.setState({ statusMessage: response.data.status_message, msisdn: "", isShowError: false, isLoading: false }), 2000);
                }

            })
            .catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.status_message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div>

                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <Contacts /> : null
                        }

                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Col md={12}>
                            <h3>Add Contact</h3><br />

                        </Col>

                        
                        <div className="row">
                            <div className="col-md-6">
                                <FileUploadDefault />

                            </div>

                            {/* <Link to="/contacts">
                                        <Button className="pull-right"
                                            color="primary" outline>Contacts</Button>
                                    </Link> */}



                            <div className="col-md-6">
                                <Col md={12} lg={12}>
                                    <Card className="card--not-full-height">
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h5>Fill the Below Field to add contacts</h5>
                                                </div>
                                                <div className="col-md-6">
                                                    {/* <Link to="/contacts">
                                                        <Button className="pull-right"
                                                            color="primary" outline>Contacts</Button><br /> <br /><br />
                                                    </Link> */}
                                                </div>
                                            </div>


                                            {this.state.showError ? <div style={{ color: 'red' }}>
                                                {this.state.statusMessage}

                                            </div> : null}<br></br>

                                            {this.state.isShowError ? (
                                                <div
                                                    color="success"
                                                    style={{ fontSize: "13px", color: "green" }}>
                                                    {this.state.statusMessage}
                                                </div>

                                            ) : null}<br></br>
                                            <form className="col-md-8 offset-md-2" onSubmit={this.onSubmit}>

                                                <div className="form-group">
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>

                                                    <label className="form-label">Phone Number</label>
                                                    <input id="input" type="text" className="form-control input-md"
                                                        name="msisdn" required placeholder="Enter Phone Number"
                                                        value={this.state.msisdn} onChange={this.handleChange} />
                                                </div>
                                                <div className="col-md-12">
                                                <br />
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right
                                                                 " >
                                                        {this.state.isLoading ? "Please Wait..." : "Submit"}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;

                                                 </div>
                                            </form>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </>

                )}
            </div>
        )
    }

}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserSearch } from '../../../users/usersearch';
import { UserDetails } from '../../../users/userdetails';
import { Games } from '../../../users/games';
import { OutBondMessages } from '../../../users/outbondmsgs';
import { BoundMessages } from '../../../users/bounds';
import { Statement } from '../../../users/statement';

export default () => (
  <Switch>
    <Route path="/user" component={UserSearch} />
    <Route path="/userdetail" component={UserDetails} />
    <Route path="/game" component={Games} />

    <Route path="/outgoing" component={OutBondMessages} />
    <Route path="/allmessages" component={BoundMessages} />
    <Route path="/statement" component={Statement} />

    {/* <Route path="/default_pages/faq" component={FAQ} />
    <Route path="/default_pages/gallery" component={Gallery} />
    <Route path="/default_pages/invoice_template" component={InvoiceTemplate} />
    <Route path="/default_pages/pricing_cards" component={PricingCards} />
    <Route path="/default_pages/project_summary" component={ProjectSummary} />
    <Route path="/default_pages/search_results" component={SearchResults} />
    <Route path="/default_pages/text_editor" component={TextEditor} /> */}
  </Switch>
);

import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Survey } from './survey';
import { TOKEN } from '../../_helpers/token';

export class editSurvey extends React.Component {

    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            isLoading: false,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
        }
    }

    onSubmit(e) {
        e.preventDefault();
        let formData = {
            "name": this.state.name
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });

        axios.post(baseURL + 'surveys', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+TOKEN
            },
        })
            .then((response) => {
                console.log("bayo", response)
                // eslint-disable-next-line
                if (response.data.status == true) {
                    console.log("records", formData)

                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                    setTimeout(() => this.setState({ name: "", isShowError: false, isLoading: false  }), 2000);
                 
                } else {

                    this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });

                    // window.setTimeout(function () {
                    //     window.location.href = "/session";
                    // }, 2000);
                }

            })
            .catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.status_message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div className="pcoded-main-container">
 
                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <Survey /> : null
                        }

                    </div>
                )}

                {/* {!hideComponent && (
                   
                )
                } */}

            </div>
        )

    }

}

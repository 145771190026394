import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

export class BoundMessages extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "in_text",
                TrOnlyClassName: 'tsw',
                text: "In Text",
                className: "tsss",
                align: "left"
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "In Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "response",
                TrOnlyClassName: 'tssc',
                text: "Response",
                className: "tssc",
                align: "left"
            },

            {
                key: "dates1",
                TrOnlyClassName: 'tsc',
                text: "Out Date",
                className: "tsc",
                align: "left"
            },
            {

                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Send",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Enter ID...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + "bound?mobile=" + localStorage.getItem("phone") + '&' + queryString
        console.log("query", url);

        console.log("token", TOKEN)

        axios.get(url, axiosConfig, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((branchResponse) => {
                this.setState({
                    admins: branchResponse.data,
                    isLoading: false,
                }, function () {

                    var data = [];
                    console.log("BUG", this.state.admins)

                    for (let i = 0; i < this.state.admins.length; i++) {
                        let mail;
                        let mbalance;
                        this.setState({
                            total: localStorage.getItem("counts")
                        })
                        if (this.state.admins[i].sent == null) {
                            mail = { mail: "_" };
                        } else {
                            mail = { mail: this.state.admins[i].sent.toString() }
                        }
                        if (this.state.admins[i].balance === 0) {
                            mbalance = { mbalance: "0.0" };
                        } else {
                            mbalance = { mbalance: this.state.admins[i].balance };
                        }
                        let date = { dates: moment(this.state.admins[i].in_time).utc().format("DD-MMM-yyyy HH:mm:ss") };
                        let date1 = { dates1: moment(this.state.admins[i].out_time).utc().format("DD-MMM-yyyy HH:mm:ss") };

                        data.push(Object.assign(mail, mbalance, date, date1, this.state.admins[i]));
                        this.setState({
                            data: data
                        })
                        console.log("bugs", data);
                    }

                }
                );
            })
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    render() {
        // console.log("Load", isLoading);
        return (
            <div>
                <>
                    <Col md={12}>
                        <h3>All Customer Messages</h3><br />
                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Link to="/userdetail">
                                                <Button className="pull-right"
                                                    color="primary" outline>Go Back</Button><br /> <br /><br />
                                            </Link>
                                        </div>
                                    </div>
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';

export class BetSummary extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "DATE",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "NOOFBETS",
                TrOnlyClassName: 'tsc',
                text: "NoofBets",
                className: "tsc",
                align: "left"
            },
            {
                key: "TOTALSTAKE",
                TrOnlyClassName: 'tsc',
                text: "TotalStake",
                className: "tsc",
                align: "left"
            },
            {
                key: "PAYOUTS",
                TrOnlyClassName: 'tsc',
                text: "Payouts",
                className: "tsc",
                align: "left"
            },

            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "LuckyBox",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "LuckyPlayer(Stake)",
                className: "tsc",
                align: "left"
            },
            {
                key: "JACKPOTS",
                TrOnlyClassName: 'tsc',
                text: "JackPot",
                className: "tsc",
                align: "left"
            },
            {
                key: "SUREBETS",
                TrOnlyClassName: 'tsc',
                text: "SuperBet",
                className: "tsc",
                align: "left"
            },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 20,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                no_data_text: "No data was found",
                filter: "Enter date (YYYY-MM-DD)",

                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            },
            button: {
                excel: true,
                print: true,
                csv: true
            }   
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        let url = baseURL + "summary?" + queryString
        console.log("query", url);
        this.setState({
            isLoading: true,
        })
        axios.all([
            axios.get(url),
            axios.get(baseURL + "countSummary"),
        ]).then(axios.spread((branchResponse, entityResponse,) => {
            this.setState({
                admins: branchResponse.data,
                mdata: entityResponse.data.customer,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)
                    if (this.state.admins.length > 0) {
                        for (let i = 0; i < this.state.admins.length; i++) {

                            let mail;
                            let mbalance;

                            // eslint-disable-next-line
                            let paystackS = { JACKPOTS: this.state.admins[i].JACKPOT.toString() }
                            let SUREBET = { SUREBETS: this.state.admins[i].SUREBET.toString() }
                            // eslint-disable-next-line
                            if (this.state.admins[i].LUCKYBOX == 0) {
                                mail = { mail: "0.00" };
                            } else {
                                mail = { mail: this.state.admins[i].LUCKYBOX };
                            }


                            // eslint-disable-next-line
                            if (this.state.admins[i].LUCKYPLAYER == 0) {
                                mbalance = { mbalance: "0.00" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].LUCKYPLAYER };
                            }


                            data.push(Object.assign(mail, mbalance,paystackS,SUREBET, this.state.admins[i]));


                            this.setState({
                                data: data,
                                isLoading: false
                            })
                            console.log("bugs", data);
                        }
                    } else {

                        this.setState({
                            data: data,
                            isLoading: false
                        })
                        console.log("bugs", data);

                    }


                }
            );
        }))

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    render() {
        return (
            <div >
                < >
                    <Col md={12}>
                        <h3>Summary</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.mdata}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopSellingProducts from './components/TopSellingProducts';
import { deleteNewOrderTableData } from '../../../redux/actions/newOrderTableActions';
import { NewOrderTableProps } from '../../../shared/prop-types/TablesProps';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import ABTestingAnalytics from '../Default/components/ABTestingAnalytics';
import BestSellingRegions from '../Default/components/BestSellingRegions';

class ECommerceDashboard extends PureComponent {
  static propTypes = {
    newOrder: NewOrderTableProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  onDeleteRow = (index, e) => {
    const { dispatch, newOrder } = this.props;

    e.preventDefault();
    const arrayCopy = [...newOrder];
    arrayCopy.splice(index, 1);
    dispatch(deleteNewOrderTableData(arrayCopy));
  };

  render() {
    const { rtl } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
        </Row>
        <Row>
{/* 
          <TotalPageViews />
          <Staffs />
          <NewUsers />
          <BounceRate /> */}
          
        </Row>

        <Row>
          <ABTestingAnalytics dir={rtl.direction} />
          <TopSellingProducts dir={rtl.direction} />
          <BestSellingRegions />
          {/* <GoalsCompletion /> */}
        </Row>




      </Container>
    );
  }
}

export default connect(state => ({
  newOrder: state.newOrder.items,
  rtl: state.rtl,
}))(withTranslation('common')(ECommerceDashboard));

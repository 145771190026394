import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';

import * as moment from 'moment';

export class InterSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [{
            key: "idx",
            text: "#",
            TrOnlyClassName: 'tsc',
            className: "tsc",
            align: "left",
        },
        {
            key: "dates2",
            TrOnlyClassName: 'tsc',
            text: "Date",
            className: "tsc",
            align: "left"
        },
        {
            key: "withdrawal_count",
            TrOnlyClassName: 'tsc',
            text: "Withdrawal Count",
            className: "tsc",
            align: "left"
        },
        {
            key: "total_withdrawals",
            TrOnlyClassName: 'tsc',
            text: "Total Withdrawals",
            className: "tsc",
            align: "left"
        },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],

            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,

            language: {
                loading_text: "Please be patient while data loads...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }   
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        const config = {
            headers: {
                'Authorization': TOKEN
            }
        };
        // if (window.user.data.user.role_id == "1") {
        axios.all([
            axios.get(baseURL + "getDailyIncome", config),
        ]).then(axios.spread((branchResponse, entityResponse) => {
            this.setState({
                admins: branchResponse.data,
                isLoading: false
            },
                function () {
                    var data = [];
                    for (let i = 0; i < this.state.admins.length; i++) {

                        let index = { idx: i + 1 };

                        let dates = { dates2: moment(this.state.admins[i].date).format('DD MMM, YYYY') };

                        data.push(Object.assign(index, dates, this.state.admins[i]));
                        this.setState({
                            data: data
                        })
                        console.log("dataente", data);

                    }


                }
            );
        }))
    }



    render() {
        return (
            <div >
                < >
                    <Col md={12}>
                        <h3>InterSwitch Withdrawal</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    < ReactDatatable config={this.config}
                                        records={this.state.data}
                                        id="tsc"
                                        columns={this.columns}
                                        loading={this.state.isLoading}
                                        extraButtons={this.extraButtons}
                                    />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}

import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import $ from 'jquery'

window.$ = window.jQuery = require('jquery')



// require("http://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js")

require('../../_assets/jss/sms_counter.js')

export class sendSMS extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showComponent: false,
            hideComponent: true,
            selected: [],
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            alert_color: '',
            isLoading: false,
            isShowError: false,
            name: '',
            statusMessage: '',
            showError: false,
        }
    }
    componentDidMount() {
        $('#message').countSms('#sms-counter');
    }

    onSubmit(e) {
        e.preventDefault();
        let formData = {
            "message": this.state.message,
        }
        console.log("DATA", JSON.stringify(formData))
        this.setState({ isLoading: true });
        axios.post(baseURL + 'customers/customerbalanc.php', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
                console.log('bayoo', response.status)
                if (response.data.status === 'true') {
                    this.setState({ statusMessage: " Bulk sms send Successfully", isShowError: true, alert_color: "alert alert-success", isLoading: false });
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 1000);

                } else {

                    this.setState({ statusMessage: "error", alert_color: "alert alert-danger", isShowError: true, isLoading: false });
                }

            }, error => {
                this.setState({ statusMessage: "error", isShowError: true, alert_color: "alert alert-danger", isLoading: false });
            });
    }

    Constituency() {
         // eslint-disable-next-line
        return (this.state.type && (this.state.type.length == 0 || this.state.type.length > 0) &&
            this.state.type.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }


    onSelectChangeConstitueny = value => {
        this.setState({ constituency_id: value.value.toString() });
    };

    onSelectChange = value => {

        this.setState({ entity_id: value.value.toString() });
    };


    handleChangeGender = (event) => {
        this.setState({ selectedGender: event.target.value });
        //alert(event.target.value)
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() { }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    changeList(setSelected) {
        this.setState({ setSelected });
    }
    render() {
        const { hideComponent } = this.state;
      
        return (
            <div className="pcoded-main-container">

                {!hideComponent && (
                    <>
                        <Col className="col-md-8 offset-md-2">
                            <Card>
                                <CardBody><br />
                                    <form onSubmit={this.onSubmit}>

                                        <br />
                                        <h4>Create Message</h4>
                                        {this.state.isShowError ? <div className={this.state.alert_color}
                                            style={{ fontSize: '15px' }}>
                                            {this.state.statusMessage}</div> : null}
                                        <br></br>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-8" >
                                                    <textarea name="message" style={{ paddingTop: '20px' }} onChange={this.handleChange}
                                                     className="col-md-12" placeholder="type your message here"
                                                        id="message" cols="col-md-1" rows="20"></textarea>
                                                </div>
                                                <div className="col-md-4">
                                                    <ul id="sms-counter">
                                                        <li>Length: <span class="length"></span></li>
                                                        <li>SMS: <span class="messages"></span></li>
                                                        <li>Per Message: <span class="per_message"></span></li>
                                                        <li>Remaining: <span class="remaining"></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div><br />
                                        <div className="col-md-12">
                                            <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right
                                                        " >
                                                {this.state.isLoading ? "Please Wait..." : "Send "}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                    </form>

                                </CardBody>
                            </Card>
                        </Col>
                    </>

                )
                }

            </div>
        )

    }


}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BetSummary } from '../../../Reports/summary';
import { EOD } from '../../../Reports/eod';

export default () => (
  <Switch>
    <Route path="/summary" component={BetSummary} />
    <Route path="/eod" component={EOD} />
  </Switch>
);

import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';

export class DailyDeposit extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "dates2",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "total_deposits",
                TrOnlyClassName: 'tsc',
                text: "Total Deposits",
                className: "tsc",
                align: "left"
            },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                // extra: true,
                // print: false
            },
            show_filter: true,
            show_pagination: true,
            pagination: 'basic',
            page_size: 10,
            show_length_menu: true,

            language: {
                loading_text: "Please be patient while data loads...",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        let url = baseURL + "dailydeposit"
        console.log("query", url);
        const config = {
            headers: {
                'Authorization': TOKEN
            }
        };
        axios.all([
            axios.get(url, config),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data,
                isLoading: false,
            },
                function () {
                    console.log("bugs", this.state.mdata);
                    var data = [];
                    if (this.state.admins.length > 0) {
                        for (let i = 0; i < this.state.admins.length; i++) {
                            let dates = { dates2: moment(this.state.admins[i].date).format('DD MMM, YYYY') };

                            data.push(Object.assign(dates, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", i);
                        }
                    } else {
                        this.setState({
                            data: data
                        })
                    }

                }
            );
        }))

    }

    render() {
        // console.log("Load", isLoading);
        return (
            <div>
                < >
                    <Col md={12}>
                        <h3>Daily Deposits</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        id="tsc"
                                        loading={this.state.isLoading} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HouseIncome } from '../../../Finance/income';
import { DailyDeposit } from '../../../Finance/dailydeposits';
import { InterSwitch } from '../../../Finance/interswitch';
import { MTNDeposit } from '../../../Finance/mtndeposit';
import { MTNSuccessRate } from '../../../Finance/mtnsuccess';
import { PayStack } from '../../../Finance/paystack';
import { PayStackWithdrawal } from '../../../Finance/paystackwi';
import { EWallet } from '../../../Finance/ewallet';
import { STAKE } from '../../../Finance/stake';
import { WinLoss } from '../../../Finance/winloss';
import { NLArchive } from '../../../Finance/nlvarchive';


export default () => (
  <Switch>
    <Route path="/houseincome" component={HouseIncome} />
    <Route path="/dailydeposit" component={DailyDeposit} />
    <Route path="/interswitch" component={InterSwitch} />
    <Route path="/mtndeposit" component={MTNDeposit} />
    <Route path="/mtnsuccess" component={MTNSuccessRate} />
    <Route path="/paystack" component={PayStack} />
    <Route path="/ewallet" component={EWallet} />
    <Route path="/winloss" component={WinLoss} />
    <Route path="/nlarchive" component={NLArchive} />

    <Route path="/stake" component={STAKE} />
    <Route path="/paystackwith" component={PayStackWithdrawal} />
   </Switch>
);

import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';

export class OtherWinners extends React.Component {
    constructor(props) {
        super(props);
        this.submitNewUpdate = this.submitNewUpdate.bind(this);
        this.columns = [

            {
                key: "date",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            // {
            //     key: "name",
            //     TrOnlyClassName: 'tsc',
            //     text: "Name",
            //     className: "tsc",
            //     align: "left"
            // },
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone",
                className: "tsc",
                align: "left"
            },
            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            // button: {
            //     excel: true,
            //     // extra: true,
            //     // print: false
            // },
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Enter date (YYYY-MM-DD)",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }


    componentDidMount() {
        this.getData();
    }
    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let url = baseURL + "otherWinners?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url),
        ]).then(axios.spread((branchResponse) => {
            this.setState({
                admins: branchResponse.data,
                isLoading: false,
            },
                function () {

                    var data = [];
                    console.log("BUG", this.state.admins)
                    // let names;

                    for (let i = 0; i < this.state.admins.length; i++) {
                        this.setState({
                            total: this.state.admins[0].total_page
                        })
                        // if (this.state.admins[i].name === null) {
                        //     names = { name: "dd" };
                        // } else {
                        //     names = { name: this.state.admins[i].name };
                        // }
                        data.push(Object.assign(this.state.admins[i]));
                        this.setState({
                            data: data,
                            isLoading: false
                        })
                        console.log("bugs", i);
                    }

                }
            );
        }))

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    editRecord(record) {
        console.log("Edit Record", record);
        this.setState({
            showModal: true,
            hideComponent: true,
        })
    }

    submitNewUpdate(e) {
        e.preventDefault();
        const userInputData = {
            "branch_name": this.state.branch_name,
            "branch_description": this.state.branch_description,
        }
        console.log("reecrd", userInputData);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + TOKEN
            }
        };
        console.log("DATA", this.state.admin);
        this.setState({ isLoading: true })

        axios.put(baseURL + "branches/" + this.state.admin, userInputData, config).then(response => {
            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
            } else {
                this.setState({ statusMessage: response.data.status_message, showError: true, isShowError: false, isLoading: false });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: false, showError: true, isLoading: false });
        });
        this.setState({ school_name: '', school_code: '', postal_address: '' })

    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    deleteRecord(record) {
        console.log("Delete Record", record);

        this.setState({
            school_id: record.id,
        });
        const config = {
            headers: {
                "Authorization": `Bearer ` + TOKEN
            }
        };
        axios.delete(baseURL + "branches/" + record.id, config).then(response => {

            if (response.data.status) {
                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
                window.setTimeout(function () {
                    window.location.reload();
                }, 1000);

            } else {

                this.setState({ statusMessage: response.data.status_message, isShowError: true, isLoading: false });
            }

        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    render() {
        return (
            <div  >
                < >
                    <Col md={12}>
                        <h3>Other Winners</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
     
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} /> </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';

export class STAKE extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "DATE",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "BETTYPE",
                TrOnlyClassName: 'tsc',
                text: "Bet Type",
                className: "tsc",
                align: "left"
            },
            {
                key: "TOTALSTAKE",
                TrOnlyClassName: 'tsc',
                text: "Total Stake",
                className: "tsc",
                align: "left"
            },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads..."
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }


    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
            this.setState({
                isLoading: true,
            })
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            let url = baseURL + "stake?" + queryString
            console.log("query", url);
            console.log("token", TOKEN)
            axios.all([
                axios.get(url, axiosConfig),
            ]).then(axios.spread((branchResponse) => {
                this.setState({
                    admins: branchResponse.data,
                    isLoading: false,
                }, function () {
                    this.setState({
                        total: this.state.admins[0].total_page
                    })
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0) {
                        for (let i = 0; i < this.state.admins.length; i++) {
                            let mail;
                            let mbalance;

                            if (this.state.admins[i].email == null) {
                                mail = { mail: "Not provided" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            data.push(Object.assign(mail, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);
                        }
                    } else {
                        this.setState({
                            data: data,
                            isLoading: false,

                        })
                        console.log("bugs", data);
                    }
                }
                );
            }))
     
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    render() {
        return (
            <div>
                < >
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >

                                    <div>
                                        <h3>Stake</h3><br /><br />
                                    </div>
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
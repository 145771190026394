import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';

export class MTNSuccessRate extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "DATE",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            },
            {
                key: "STATUS",
                TrOnlyClassName: 'tsc',
                text: "Status",
                className: "tsc",
                align: "left"
            },
            {
                key: "COUNT",
                TrOnlyClassName: 'tsc',
                text: "Count",
                className: "tsc",
                align: "left"
            },
            {
                key: "Amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,          
            show_pagination: false,
            show_info: false,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Enter date (YYYY-MM-DD)",
                no_data_text: "No data was found",
                // filter_value: "dfh"
                // pagination: {
                //     first: "First",
                //     previous: <span>&#9668;</span>,
                //     next: <span>&#9658;</span>,
                //     last: "Last"
                // }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }


    getData = (queryString = "") => {

        this.setState({
            isLoading: true,
        })
        // alert(TOKEN)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + "getMTNDepositRates?" + queryString
        console.log("query", url);

        console.log("token", TOKEN)
        axios.all([
            axios.get(url, axiosConfig),
            axios.get(baseURL + "getCountMTNRates")
        ]).then(axios.spread((branchResponse, entityResponse,) => {
            this.setState({
                admins: branchResponse.data,
                mdata: entityResponse.data.customer,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0) {
                        for (let i = 0; i < this.state.admins.length; i++) {
                            this.setState({
                                total: this.state.admins[i].total_page
                            })
                            data.push(Object.assign(this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);
                        }
                    } else {
                        this.setState({
                            data: data,
                            isLoading: false,

                        })
                        console.log("bugs", data);
                    }
                }
            );
        }))
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }



    render() {
        // console.log("Load", isLoading);
        return (
            <div >
                <>
                    <Col md={12}>
                        <h3>MTN Deposits Success Rates</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >

                        
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }



                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        tHeadClassName="custom-header-style"

                                        dynamic={true}
                                        id="tsc"

                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}
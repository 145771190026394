import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BetPerHour } from '../../../Bets/bets';
import { JackPot } from '../../../Bets/jackpot';
import { OtherWinners } from '../../../Bets/others';
import { AverageBet } from '../../../Bets/others copy';
import { BetsPerDay } from '../../../Bets/betsperday';
import CSV from '../../../CSV';

export default () => (
  <Switch>
    <Route path="/betsperday" component={BetsPerDay} />
    <Route path="/betsperhour" component={BetPerHour} />
    <Route path="/jackpot" component={JackPot} />
    <Route path="/otherwinners" component={OtherWinners} />
    <Route path="/averagebets" component={AverageBet} />
    <Route path="/reconciledeposits" component={CSV} /> 

    {/* <Route/> */}
    </Switch>
);

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BlackList } from '../../../blackList/blacklist';
import { createBlackList } from '../../../blackList/createBlackList';

export default () => (
  <Switch>
    <Route path="/blacklist" component={BlackList} />
    <Route path="/addblacklist" component={createBlackList} />
    {/* <Route path="/default_pages/faq" component={FAQ} />
    <Route path="/default_pages/gallery" component={Gallery} />
    <Route path="/default_pages/invoice_template" component={InvoiceTemplate} />
    <Route path="/default_pages/pricing_cards" component={PricingCards} />
    <Route path="/default_pages/project_summary" component={ProjectSummary} />
    <Route path="/default_pages/search_results" component={SearchResults} />
    <Route path="/default_pages/text_editor" component={TextEditor} /> */}
  </Switch>
);
